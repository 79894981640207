<template>
    <Form @submit="handleSubmit" v-slot="{ errors }">
        <div class="modal" v-if="modelValue" :class="{ active : modelValue }">
            <div class="modal_container">
                <div class="modal_header border-bottom">
                    <h1>Fit Funnels Integration</h1>
                    <button class="close_btn left_out" @click="closeModal()"><i class="fas fa-long-arrow-alt-right"></i></button>
                </div>
                <div class="modal_body">
                    <div class="setting_wpr">
                        <div class="form_grp">
                            <div class="group_item">
                                <label class="input_label">Webhook Name</label>
                                <div class="field_wpr" :class="{ 'has-error': errors.title }">
                                    <Field autocomplete="off" type="text" name="title" v-model="form.title" placeholder="Webhook Name"></Field>
                                </div>
                                <ErrorMessage name="title" class="text-danger" />
                            </div>
                        </div>
                        <div class="form_grp">
                            <div class="group_item">
                                <label class="input_label">
                                    Select Tags
                                    <a class="option" @click="createTags = !createTags">Create a Tag</a>
                                </label>
                                <div class="field_wpr" :class="{ 'has-error': errors.tag }">
                                    <Field autocomplete="off" name="tag" v-model="form.tags">
                                        <multiselect
                                            v-model="form.tags"
                                            mode="tags"
                                            label="name"
                                            value-prop="id"
                                            :searchable="true"
                                            :group-select="true"
                                            :options="tagsGroup"
                                            :groups="true"
                                            group-label="type"
                                            group-options="tags"
                                            :group-hide-empty="true"
                                            placeholder="Select Tags"
                                        >
                                            <template v-slot:grouplabel="{ group }">
                                                <span :class="group.type.replace(' ', '-').toLowerCase()">{{ group.type }}</span>
                                            </template>
                                            <template v-slot:option="{ option }">
                                                <span>{{ option.name }}</span>
                                            </template>
                                            <template v-slot:tag="{ option, handleTagRemove, disabled }">
                                                <div class="multiselect-tag" :class="option.type.replace(' ', '-').toLowerCase()">
                                                  {{ option.name }}
                                                  <span
                                                    v-if="!disabled"
                                                    class="multiselect-tag-remove"
                                                    @mousedown.prevent="handleTagRemove(option, $event)"
                                                  >
                                                      <span class="multiselect-tag-remove-icon"></span>
                                                  </span>
                                                </div>
                                            </template>
                                        </multiselect>
                                    </Field>
                                </div>
                                <ErrorMessage name="tag" class="text-danger" />
                            </div>
                        </div>
                        <div v-if="createTags" class="form_grp">
                            <Form v-slot="{ errors }" ref="tag-form">
                                <div class="group_item">
                                    <label class="input_label">Create a New Tag</label>
                                    <div class="field_wpr has_suffix" :class="{ 'has-error': errors.name }">
                                        <Field autocomplete="off" type="text" v-model="tagForm.name" name="name" placeholder="Enter Tag Name.." rules="required" />
                                        <span class="suffix pointer" @click="handleTagCreate"><i class="fa fa-spinner fa-spin" v-if="tagLoader"></i> {{ tagLoader ? 'Creating' : 'Create' }}</span>
                                    </div>
                                    <ErrorMessage name="name" class="text-danger" />
                                </div>
                            </Form>
                        </div>
                        <div class="form_grp">
                            <div class="group_item">
                                <label class="input_label">Webhook URL</label>
                                <div class="field_wpr" :class="{ 'has-error': errors.url }">
                                    <Field autocomplete="off" name="url" v-model="form.url" rules="required">
                                        <textarea v-model="form.url" name="url" cols="30" rows="10"></textarea>
                                    </Field>
                                </div>
                                <ErrorMessage name="url" class="text-danger" />
                            </div>
                        </div>
                        <div class="action_wpr mb-5">
                            <button class="btn cancel_btn bg-light" type="button" @click="generateCode()"><i class="fa fa-spin fa-spinner" v-if="loader"></i> {{ loader ? 'Generating' : 'Generate Code' }}</button>
                        </div>
                    </div>
                    <div class="result_wpr small m-0" v-if="fitFunnelWebhooks.length">
                        <table>
                            <thead>
                                <tr>
                                    <th>Webhook</th>
                                    <th class="action"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(webhook, w) of fitFunnelWebhooks" :key="w">
                                    <td>
                                        <p><b>Name:</b> {{ webhook.title }}</p>
                                        <p><b>URL:</b> {{ webhook.url }}</p>
                                    </td>
                                    <td>
                                        <button type="button" @click="editWebhook(webhook)"><i class="far fa-edit"></i></button>
                                        <button type="button" @click="deleteWebhook(webhook)"><i class="fas fa-trash-alt danger"></i></button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="modal_footer">
                    <button :disabled="loader" type="button" class="btn cancel_btn" @click="closeModal()">Cancel</button>
                    <button :disabled="loader" type="button" class="btn save_btn" @click="copyWebhookUrl">Copy</button>
                    <button :disabled="loader || saveLoader" class="btn save_btn"><i class="fa fa-spin fa-spinner" v-if="saveLoader"></i> {{ saveLoader ? 'Saving' : 'Save' }}</button>
                </div>
            </div>
        </div>
    </Form>
</template>

<script>
    import { copyText } from 'vue3-clipboard'
    import { Field, Form, ErrorMessage } from 'vee-validate'
    import { mapState, mapActions } from 'vuex'

    import Toastr from '@/utils/Toastr'
    import Helper from '@/utils/Helper'
    import Swal from 'sweetalert2'

    export default {
        name: 'Fit Funnel Integration',

        data () {
            return {
                form: {
                    title: '',
                    tags: [],
                    url: '',
                },
                loader: false,
                createTags: false,
                saveLoader: false,
                tagForm: {
                    name: '',
                    type: 'leads',
                    uniqueId: 'bulk-action',
                },
            };
        },

        props: {
            modelValue: Boolean,
        },

        emits: ['update:modelValue'],

        components: {
            Field,
            Form,
            ErrorMessage,
        },

        watch: {
            user: {
                handler () {
                    const vm = this;

                    vm.resetForm();
                },
                deep: true
            },

            modelValue (value) {
                if (value) {
                    const vm = this;
                    document.body.classList.add('modal-open');

                    if (vm.tagsGroup.length == 0) {
                        vm.getTags();
                    }

                    setTimeout(function () {
                        vm.getFitFunnelWebhooks();
                    }, 1000);

                    vm.form       = { title: '', tags: [], url: '' };
                    vm.loader     = false;
                    vm.createTags = false;
                    vm.saveLoader = false;
                    vm.tagForm    = { name: '', type: 'leads', uniqueId: 'bulk-action' };
                } else {
                    document.body.classList.remove('modal-open');
                }
            },
        },

        computed: mapState({
            user: state => state.authModule.user,
            tagsGroup: state => state.tagModule.tagsGroup,
            tagLoader: state => state.tagModule.tagLoader,
            fitFunnelWebhooks: state => state.authModule.fitFunnelWebhooks,
        }),

        mounted () {
            const vm = this;

            vm.resetForm();
        },

        methods: {
            ...mapActions({
                getTags: 'tagModule/getTags',
                createTag: 'tagModule/createTag',
                getFitFunnelWebhooks: 'authModule/getFitFunnelWebhooks',
                saveFitFunnelWebhook: 'authModule/saveFitFunnelWebhook',
                deleteFitFunnelWebhook: 'authModule/deleteFitFunnelWebhook',
            }),

            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },

            resetForm () {
                const vm = this;

                vm.form = {
                    title: '',
                    tags: [],
                    url: '',
                };
            },

            handleTagCreate () {
                const vm = this;
                const form = vm.$refs['tag-form'];

                form.validate().then(({ valid }) => {
                    if (valid) {
                        vm.tagForm.setFieldError = form.setFieldError;

                        vm.createTag(vm.tagForm).then((result) => {
                            vm.createTags = false;
                            vm.tagForm.name = '';
                            vm.getTags();
                        });
                    }
                });
            },

            generateCode () {
                const vm = this;

                vm.form.url = `${process.env.VUE_APP_API_URL}/webhooks/landingi/${vm.user.id}${vm.form.tags.length ? '/'+vm.form.tags.join(',') : ''}`;
            },

            handleSubmit (params, { setFieldError, resetForm }) {
                const vm = this;

                vm.form.setFieldError = setFieldError;
                vm.saveLoader = true;

                vm.saveFitFunnelWebhook(vm.form).then((result) => {
                    if (result) {
                        vm.resetForm();
                        resetForm();
                    }

                    vm.saveLoader = false;
                });
            },

            copyWebhookUrl () {
                const vm = this;

                copyText(vm.form.url, undefined, (error, event) => {
                    if (error) {
                        Toastr.error('Failed to copy webhook url!');
                    } else {
                        Toastr.success('Webhook URL has been copied successfully!');
                    }
                })
            },

            editWebhook (webhook) {
                const vm = this;

                vm.form = {
                    id: webhook.id,
                    title: webhook.title,
                    tags: webhook.tags ? webhook.tags.split(',') : [],
                    url: webhook.url,
                };
            },

            deleteWebhook (webhook) {
                const vm = this;

                const options = Helper.swalConfirmOptions('Are you sure?', `You want to delete this webhook, you will not be able to recover this webhook.`);
                options.preConfirm = () => { return vm.deleteFitFunnelWebhook({ id: webhook.id }); }

                Swal.fire(options);
            },
        },
    }
</script>

<style scoped>
    .result_wpr {
        border-radius: 14px;
        border: 1px solid #f3f3f3;
        margin-top: 15px;
    }

    .result_wpr table {
        border-radius: 14px;
        width: 100%;
        background: #fff;
        border-collapse: collapse;
    }

    .result_wpr table thead {
        border: 0px;
        border-bottom: 1px solid #f3f3f3;
    }

    .result_wpr table tbody tr {
        border-bottom: 1px solid #f3f3f3;
        transition: all .3s ease-in-out;
    }

    .result_wpr table td {
        padding: 12px;
        font-size: 13px;
        line-height: 16px;
    }

    .result_wpr table tbody tr:last-child {
        border: 0;
    }

    .result_wpr table th {
        padding: 7px 12px;
        font-size: 13px;
        line-height: 16px;
    }

    .result_wpr thead tr th:first-child,
    .result_wpr tbody tr td:first-child {
        min-width: 70px;
        text-align: left;
        padding-left: 15px;
    }

    th.action {
        min-width: 70px;
        width: 70px;
    }
</style>
